@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #b3afb3;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b3afb3;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}
* {
  font-family: "Work Sans", sans-serif;
}
#root {
  background: white;
}

/* mui styles */

.t16lpgj {
  /* Toolbar */
  box-shadow: none !important;
  border: none !important;
}
